import React from 'react';
import place1 from '../assets/newimg/image (1).png';
import place2 from '../assets/newimg/image (4).png';
import place3 from '../assets/newimg/image (3).png';
import place4 from '../assets/newimg/image (2).png';
import place5 from '../assets/newimg/image.png';
import appImage from '../assets/img/Group148.png';

const Reason = () => {
  return (
    <div id="about" className="max-w-screen-xl mx-auto mt-12 px-4 py-8">
      <h2 className="text-3xl font-bold text-left mb-6">
        <span className="text-gray-500">Know more </span>
        <span className="text-black text-4xl relative inline-block">
          ABOUT US
          <span className="absolute left-1/2 bottom-[-12px] w-48 h-[4px] bg-white transform -translate-x-1/2 shadow-[0_0_12px_1px_#4e7b97]"></span>
        </span>
      </h2>

      <div className="flex flex-col items-center relative mt-20">
        <div className="absolute w-[2px] h-[86%] top-[6%] left-1/2 transform -translate-x-1/2 border-l-2 border-dashed border-gray-700"></div>

        {/* Local Expertise */}
        <div className="flex items-center w-full max-w-xl mb-10 mr-0 md:mr-[250px] lg:mr-[420px]">
          <div className="w-1/2 flex justify-end">
            <img src={place5} alt="Local Expertise" className="w-56 h-44 object-cover rounded-full transition-transform duration-300 hover:scale-110 hover:shadow-[0_0_12px_3px_#99d6ff]" />
          </div>
          <div className="w-1/2 pl-4 z-20">
            <div className="p-5 bg-white shadow-md rounded-lg border border-blue-300 border-b-4">Local Expertise</div>
          </div>
        </div>

        {/* Seamless Booking */}
        <div className="flex items-center w-full max-w-xl mb-10 ml-0 md:ml-[250px] lg:ml-[420px]">
          <div className="w-1/2 pr-4 text-right z-20">
            <div className="p-5 bg-white shadow-md rounded-lg border border-orange-300 border-b-4">Seamless Booking</div>
          </div>
          <div className="w-1/2 flex justify-start">
            <img src={place2} alt="Seamless Booking" className="w-56 h-44 object-cover rounded-full transition-transform duration-300 hover:scale-110 hover:shadow-[0_0_12px_3px_#ffcc99]" />
          </div>
        </div>

        {/* Safety and Security */}
        <div className="flex items-center w-full max-w-xl mb-10 mr-0 md:mr-[250px] lg:mr-[420px]">
          <div className="w-1/2 flex justify-end">
            <img src={place1} alt="Safety and Security" className="w-56 h-44 object-cover rounded-full transition-transform duration-300 hover:scale-110 hover:shadow-[0_0_12px_3px_#99d6ff]" />
          </div>
          <div className="w-1/2 pl-4 z-20">
            <div className="p-5 bg-white shadow-md rounded-lg border border-blue-300 border-b-4">Safety and Security</div>
          </div>
        </div>

        {/* Discover Hidden Treasure */}
        <div className="flex items-center w-full max-w-xl mb-10 ml-0 md:ml-[250px] lg:ml-[420px]">
          <div className="w-1/2 pr-4 text-right z-20">
            <div className="p-5 bg-white shadow-md rounded-lg border border-orange-300 border-b-4">Discover Hidden Treasure</div>
          </div>
          <div className="w-1/2 flex justify-start">
            <img src={place4} alt="Discover Hidden Treasure" className="w-56 h-44 object-cover rounded-full transition-transform duration-300 hover:scale-110 hover:shadow-[0_0_12px_3px_#ffcc99]" />
          </div>
        </div>

        {/* 24/7 Support */}
        <div className="flex items-center w-full max-w-xl mr-0 md:mr-[250px] lg:mr-[420px]">
          <div className="w-1/2 flex justify-end">
            <img src={place3} alt="24/7 Support" className="w-56 h-44 object-cover rounded-full transition-transform duration-300 hover:scale-110 hover:shadow-[0_0_12px_3px_#99d6ff]" />
          </div>
          <div className="w-1/2 pl-4 z-20">
            <div className="p-5 bg-white shadow-md rounded-lg border border-blue-300 border-b-4">24/7 Support</div>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row items-center mt-12">
        <div className="md:w-1/2">
          <h3 className="text-2xl font-semibold mb-4">Download the App</h3>
          <p className="text-lg mb-6 px-4 md:px-0">
            Ready to explore new destinations like a local? Download our tour guide app and connect with friendly, knowledgeable buddies who'll show you around! Whether you're seeking hidden gems, cultural insights, or just someone to share the adventure with.
          </p>
          <div className="flex flex-col md:flex-row justify-start space-y-4 md:space-y-0 md:space-x-4">
            <a target="_blank" rel="noopener noreferrer" href="https://onelink.to/zv2thk"><button className="bg-orange-600 text-white text-lg py-3 px-8 rounded-tl-full rounded-tr-full rounded-bl-full rounded-br-lg transition-all duration-300 hover:scale-110 hover:rounded-full hover:shadow-[0_0_15px_#FF4500] focus:outline-none">
              Download App
            </button></a>

          </div>
        </div>

        <div className="md:w-1/2 flex justify-center md:justify-end mt-6 md:mt-0">
          <img src={appImage} alt="Download App" className="w-full max-w-md" />
        </div>
      </div>
    </div>
  );
};

export default Reason;
