// import React from 'react';
// import kumbh_crowd from '../assets/img/kumbhCrowd.jpg'; // Import the background image

// const Modal = ({ onClose }) => {
//   return (
//     <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50" onClick={onClose}>
//       {/* Modal Container */}
//       <div
//         className="relative rounded-lg shadow-lg w-[80vw] h-[80vh] overflow-hidden"
//         style={{
//           backgroundImage: `url(${kumbh_crowd})`,
//           backgroundSize: 'cover',
//           backgroundPosition: 'center',
//         }}
//         onClick={(e) => e.stopPropagation()}
//       >
//         {/* Blurred Background Overlay */}
//         <div className="absolute inset-0 backdrop-blur-sm bg-black bg-opacity-30"></div>

//         {/* Cross Button (Top Right) */}
//         <button
//           onClick={onClose}
//           className="absolute top-2 right-2 bg-white rounded-full p-2 hover:bg-gray-200 transition-colors z-10"
//         >
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             className="h-6 w-6 text-gray-700"
//             fill="none"
//             viewBox="0 0 24 24"
//             stroke="currentColor"
//           >
//             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
//           </svg>
//         </button>
//         <button
//             onClick={onClose}
//             className="mt-4 px-6 py-2 bg-gray-600 text-white font-bold rounded-lg hover:bg-gray-800 transition"
//           >
            
//           </button>
        
//         {/* Modal Content */}
//         <div className="relative z-10 p-8 text-center h-full flex flex-col items-center justify-evenly">
//           {/* Header */}
//           <h2 className="lg:text-5xl sm:text-4xl md:text-4xl text-3xl font-bold text-white">
//             MAHAKUMBH SPECIAL BOOKINGS
//           </h2>

//           {/* Description */}
//           <p
//             className="text-white font-bold mb-6 py-12 px-6 lg:text-3xl sm:text-xl md:text-2xl" // Added horizontal padding (px-6)
//             style={{
//               backgroundColor: '#285A84', // Blue background
//               marginLeft: '6%', // 15% margin from the left
//               width: '100%', // Touch the right end
//               borderBottomLeftRadius: '80px', // Curved border at bottom-left
//               borderTopLeftRadius: '0', // No curve at top-left
//               lineHeight: '1.6', // Improve readability
//               overflow: 'hidden', // Prevent overflow
//               wordWrap: 'break-word', // Ensure text wraps
//             }}
//           >
//             Need Cabs, Hotels or Tour Guides in Prayagraj, Ayodhya, Varanasi,
//             Lucknow, and other cities?
//           </p>

//           {/* Call to Action */}
//           <div>
//             <a
//               href="tel:+916393106700"
//               className="text-white text-xl font-bold hover:text-blue-800 transition-colors"
//             >
//               <button className="font-bold rounded-tr-full rounded-br-full rounded-bl-full rounded-tl-none px-6 py-2 bg-[#ef6f53] transition duration-600 ease-in-out hover:rounded-tl-full hover:shadow-lg hover:shadow-[#fc8c7c] w-full sm:w-96">
//                 <p className="text-white font-semibold">Call Us</p>
//                 <p className="text-white">+91-6393106700</p>
//               </button>
//             </a>
//           </div>
          
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Modal;

import React from "react";
import kumbh_crowd from "../assets/img/kumbhCrowd.jpg"; // Import the background image
import logo from "../assets/img/icon1.png"; // Import the logo image

const Modal = ({ onClose }) => {
  return (
    <div className="fixed inset-0 flex items-end justify-center bg-black bg-opacity-50 z-50" onClick={onClose}>
      <div className="modal bg-white rounded-2xl shadow-lg w-full sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl p-8 relative" onClick={(e) => e.stopPropagation()}>
        {/* Close Button (Larger & More Visible) */}
        <button
          onClick={onClose}
          className="absolute top-2 right-5 text-gray-600 hover:text-gray-900 text-2xl"
        >
          ✕
        </button>

        {/* Small Logo at Left Side */}
        <img
          src={logo}
          alt="Logo"
          className="absolute top-[-20px] left-6 w-15 h-12 rounded-full shadow-md"
        />

        {/* Image */}
        <img
          src={kumbh_crowd}
          alt="Mahakumbh"
          className="rounded-xl w-full sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl h-60 md:h-80 object-cover mx-auto my-6"
        />

        {/* Title */}
        <h2 className="text-lg font-bold text-gray-800 text-center">
          MAHAKUMBH SPECIAL BOOKINGS
        </h2>

        {/* Description */}
        <p className="text-gray-800 text-sm text-center mt-2 mb-4">
            Need Cabs, Hotels or Tour Guides in Prayagraj, Ayodhya, Varanasi,
            Lucknow, and other cities?
        </p>

        {/* Call Button */}
        {/* <div className="flex justify-center mt-4">
          <a href="tel:+916393106700" className="w-full">
            <button className="bg-green-500 text-white w-full py-2 rounded-lg font-semibold hover:bg-green-600 transition">
              Call Us
            </button>
          </a>
        </div> */}
        <div>
            <a
            href="tel:+916393106700"
            className="text-white text-l font-bold hover:text-blue-800 transition-colors"
            >
            <button className="font-bold rounded-tr-full rounded-br-full rounded-bl-full rounded-tl-none px-6 py-2 bg-[#ef6f53] transition duration-600 ease-in-out hover:rounded-tl-full hover:shadow-lg hover:shadow-[#fc8c7c] w-full">
                <p className="text-white font-semibold">Call Us</p>
                <p className="text-white">+91-6393106700</p>
            </button>
            </a>
        </div>
      </div>
    </div>
  );
};

export default Modal;
