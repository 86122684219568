import React, { useState, useEffect } from 'react';
import './App.css';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import TravelCarousel from './components/TravelCarousel';
import Reason from './components/Reason';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CustomerTerms from './Pages/CustomerTerms.jsx';
import TourGuideTerms from './Pages/Tourguide.jsx';
import TourBuddyTerms from './Pages/TourBuddies.jsx';
import PrivacyPolicy from './Pages/PrivacyPolicy.jsx';
import Blog from './Pages/Blog.jsx';
import BlogOne from './BlogPages/BlogOne.jsx';
import BlogTwo from './BlogPages/BlogTwo.jsx';
import AdminPanel from './Pages/Admin.jsx';
import Contact from './components/Contact';
import Modal from './components/Modal'; // Import the Modal component

function HomePage() {
  // const [showModal, setShowModal] = useState(true); // Always show modal on page load
  const [showModal, setShowModal] = useState(false); // Initially hidden

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowModal(true); // Show modal after homepage loads
    }, 1000); // Delay of 2 seconds

    return () => clearTimeout(timer); // Cleanup function
  }, []);

  const handleCloseModal = () => {
    setShowModal(false); // Close the modal
  };

  return (
    <>
      {/* Show modal only on the home page */}
      {showModal && <Modal onClose={handleCloseModal} />}
      <Hero />
      <TravelCarousel />
      <Reason />
      <Contact />
    </>
  );
}

function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          {/* Home route with modal */}
          <Route path="/" element={<HomePage />} />
          
          <Route path="/customer-terms" element={<CustomerTerms />} />
          <Route path="/tour-guide-terms" element={<TourGuideTerms />} />
          <Route path="/tour-buddy-terms" element={<TourBuddyTerms />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/blog-one" element={<BlogOne />} />
          <Route path="/blog-two" element={<BlogTwo />} />
          <Route path="/admin" element={<AdminPanel />} />
          <Route path="/blog" element={<Blog />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
