import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../assets/img/voyease_logo.png';

const Navbar = () => {
  const [buttonStyle, setButtonStyle] = useState('bg-[#f4441c] text-white hover:shadow-lg hover:shadow-[#fc8c7c]');
  const [isOpen, setIsOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('home');
  const navigate = useNavigate();
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleScroll = (section) => {
    // Check if we are on the homepage or not
    const element = document.getElementById(section);

    if (location.pathname !== '/') {
      // If we are not on the homepage, navigate to the homepage first
      navigate('/');
      setTimeout(() => {
        // After navigation, ensure the element is found and scroll to it
        if (element) {
          const navHeight = document.querySelector('.bg-white').offsetHeight;
          window.scrollTo({
            top: element.offsetTop - navHeight, // Adjust scroll position by navbar height
            behavior: 'smooth',
          });
        }
      }, 100);
    } else {
      // If we are already on the homepage, just scroll directly
      setActiveSection(section);
      if (element) {
        const navHeight = document.querySelector('.bg-white').offsetHeight;
        window.scrollTo({
          top: element.offsetTop - navHeight, // Adjust scroll position by navbar height
          behavior: 'smooth',
        });
      }
    }
  };


  const handleBlogNavigation = () => {
    setActiveSection('blog');
    navigate('/blog');
  };

  const handleClickColor = () => {
    setButtonStyle('bg-white text-[#fc3c0c] border-2 border-solid border-[#fc3c0c] shadow-lg');
  }

  return (
    <div className="bg-white sticky top-0 z-50 flex justify-between items-center px-8 h-50">
      <div>
        <img src={logo} alt="Logo" className="h-20 w-auto ml-0 sm:ml-32 m-4" />
      </div>

      {/* Hamburger Menu Icon for Mobile */}
      <div className="md:hidden">
        <button onClick={toggleMenu} className="text-3xl">
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </button>
      </div>

      {/* Navigation Links for Desktop */}
      <nav className="hidden md:flex justify-between items-center space-x-8 mr-20">
        <ul className="flex space-x-8">
          <li
            className={`cursor-pointer relative ${activeSection === 'home' ? 'text-[#f4441c]' : ''} font-semibold`}
            onClick={() => handleScroll('home')}
          >
            Home
            {activeSection === 'home' && (
              <div className="absolute -bottom-2 left-0 right-0 h-1 bg-[#f4441c] rounded-tl-full rounded-tr-full"></div>
            )}
          </li>
          <li
            className={`cursor-pointer relative ${activeSection === 'about' ? 'text-[#f4441c]' : ''} font-semibold`}
            onClick={() => handleScroll('about')}
          >
            About Us
            {activeSection === 'about' && (
              <div className="absolute -bottom-2 left-0 right-0 h-1 bg-[#f4441c] rounded-tl-full rounded-tr-full"></div>
            )}
          </li>
          <li
            className={`cursor-pointer relative ${activeSection === 'contact' ? 'text-[#f4441c]' : ''} font-semibold`}
            onClick={() => handleScroll('contact')}
          >
            Contact Us
            {activeSection === 'contact' && (
              <div className="absolute -bottom-2 left-0 right-0 h-1 bg-[#f4441c] rounded-tl-full rounded-tr-full"></div>
            )}
          </li>
          <li
            className={`cursor-pointer relative ${activeSection === 'blog' ? 'text-[#f4441c]' : ''} font-semibold`}
            onClick={handleBlogNavigation}
          >
            Our Blog
            {activeSection === 'blog' && (
              <div className="absolute -bottom-2 left-0 right-0 h-1 bg-[#f4441c] rounded-tl-full rounded-tr-full"></div>
            )}
          </li>
        </ul>
        <div>
          <a target='_blank' href="https://onelink.to/zv2thk">
            <button
              onClick={handleClickColor}
              className={`${buttonStyle} text-semibold px-6 py-2 rounded-full transition duration-300 animate-bounce`}
            >
              Download App
            </button>
          </a>
        </div>
      </nav>

      {/* Mobile Menu (only visible when toggled open) */}
      {isOpen && (
        <nav className="absolute top-20 left-0 w-full bg-white shadow-lg md:hidden z-50">
          <ul className="flex flex-col items-center space-y-4 py-4">
            <li className="cursor-pointer font-semibold text-[#f4441c] hover:text-white hover:bg-[#fc3c0c] transition duration-300 py-2 px-4 rounded-full" onClick={() => handleScroll('home')}>
              Home
            </li>
            <li className="cursor-pointer font-semibold text-[#f4441c] hover:text-white hover:bg-[#fc3c0c] transition duration-300 py-2 px-4 rounded-full" onClick={() => handleScroll('about')}>
              About Us
            </li>
            <li className="cursor-pointer font-semibold text-[#f4441c] hover:text-white hover:bg-[#fc3c0c] transition duration-300 py-2 px-4 rounded-full" onClick={() => handleScroll('contact')}>
              Contact Us
            </li>
            <li className="cursor-pointer font-semibold text-[#f4441c] hover:text-white hover:bg-[#fc3c0c] transition duration-300 py-2 px-4 rounded-full" onClick={handleBlogNavigation}>
              Our Blog
            </li>
            <li>
              <a target="_blank" href="https://onelink.to/zv2thk">
                <button className="bg-[#f4441c] text-white px-4 py-2 rounded-full hover:bg-[#fc3c0c] transition duration-300 active:bg-orange-800">
                  Download App
                </button>
              </a>
            </li>
          </ul>
        </nav>
      )}

      {/* Modal */}
      {/* {showModal && (
        <div
          className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              toggleModal();
            }
          }}
        >
          <div className="relative bg-cover bg-center rounded-xl overflow-hidden w-11/12 md:w-1/3 flex items-center justify-center">
            <div className="p-4 text-right">
              <button onClick={toggleModal} className="text-gray-500 hover:text-gray-800">
                &times;
              </button>
            </div>
            <div className="p-4 text-center">
              
              <div className="image-wrapper">
                <img
                  src={launch_soon}
                  alt="App Image"
                  className="w-full rounded-lg"
                />
              </div>
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default Navbar;
