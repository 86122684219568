import React, { useState } from 'react';

const Contact = () => {
    const [isSubmitting, setIsSubmitting] = useState(false); // To handle loading state
    const [formMessage, setFormMessage] = useState(''); // To show form submission success or failure message

    // Add handleSubmit functionality for form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const url = "https://api.web3forms.com/submit";

        setIsSubmitting(true); // Start submitting
        setFormMessage(''); // Reset the message before submission

        try {
            const response = await fetch(url, {
                method: "POST",
                body: formData,
            });
            if (response.ok) {
                setFormMessage("Our Team has received your message!"); // Success message
            } else {
                throw new Error("Failed to send message");
            }
        } catch (error) {
            console.error("Error sending message:", error);
            setFormMessage("An error occurred while sending the message. Please try again later."); // Failure message
        } finally {
            setIsSubmitting(false); // Reset submitting state
        }
    };

    return (
        <>
            <div
                id="contact"
                className="relative bg-cover bg-center py-8"
                style={{
                    // backgroundImage: `url(${footerBg})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            >
                <div className="absolute inset-0 bg-white opacity-30"></div> {/* Optional overlay for better readability */}
                <div className="relative z-10 flex justify-center">
                    <h2 className="text-3xl font-bold text-black">Contact us for booking</h2>
                </div>
            </div>

            {/* CONTACT FORM */}
            <div className="relative z-10 bg-white py-8">
                <div className="max-w-lg mx-auto p-6 shadow-lg rounded-lg">
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <input type="hidden" name="access_key" value="f697ed8c-5c6d-497d-9f13-51824b9c04b1" />
                        <div>
                            <label className="block text-lg font-medium text-gray-700">
                                Name
                            </label>
                            <input
                                type="text"
                                name="name"
                                placeholder="Enter your name"
                                required
                                className="mt-2 p-3 w-full border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
                            />
                        </div>
                        <div>
                            <label className="block text-lg font-medium text-gray-700">
                                Mobile Number
                            </label>
                            <input
                                type="text"
                                name="email"
                                placeholder="Enter your Mobile number"
                                required
                                className="mt-2 p-3 w-full border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
                            />
                        </div>
                        <div>
                            <label className="block text-lg font-medium text-gray-700">
                                Message
                            </label>
                            <textarea
                                name="message"
                                placeholder="Write your message"
                                required
                                className="mt-2 p-3 w-full h-32 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
                            ></textarea>
                        </div>
                        <div className="flex justify-center">
                            <button
                                type="submit"
                                className={`bg-orange-600 text-white py-3 px-6 rounded-lg hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-600 ${isSubmitting ? 'cursor-not-allowed opacity-50' : ''
                                    }`}
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? 'Submitting...' : 'Submit'}
                            </button>
                        </div>
                    </form>
                    {formMessage && (
                        <div className={`mt-4 text-center ${formMessage.includes('error') ? 'text-red-500' : 'text-green-500'}`}>
                            {formMessage}
                        </div>
                    )}
                </div>
            </div>
        </>

    );
};

export default Contact;
